import logo from './logo.svg';
import './App.css';
import Co2Tracker from './Co2Tracker';
function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}

        <Co2Tracker />
      </header>
    </div>
  );
}

export default App;
