import { useState } from 'react'
import co2PerPerson from './co2PerPerson'
import './Co2Tracker.css'
export default function Co2Tracker() {

    const [activePage, setActivePage] = useState()
    const [OutputData, setOutputData] = useState({
        // E-device
        power: 0,
        co2: 395,
        budgetKg: 3000,

        kwh: 0,
        // fuel
        liters: 0,
        fuelType:'',

        co2PerPerson: co2PerPerson['Österreich'],
        // Car km
        amountKilometers: 0,
        fuelConsumtion:6
    })

    function setData(key, val) {
        setOutputData(old => {
            const buf = {
                ...old,
                [key]: val
            }
            return buf

        })
    }


    function calculateCo2(days, co2, power) {
        return Math.round((power / 1000 * 24 * days) / 1000 * (co2) * 1000) / 1000
    }
    function calculatePowerKwh(days, co2, power) {
        return Math.round((power / 1000 * 24 * days) * 10000) / 10000

    }

    // UI Elements

    const BudgetInfo = () => {
        return(
            <div>
                <p><label>Sustainable Co2 Budget kg/year</label>
                    <input
                        type="number"
                        value={OutputData.budgetKg}
                        onChange={(e) => setData('budgetKg', e.target.value)}></input>
                </p>
                <p>                            
                        <label>Country average</label><select
                            onChange={(e)=> setData('co2PerPerson', e.target.value)}
                            value={OutputData.co2PerPerson}>
                        <option label='Österreich'>7.23</option>
                            {Object.keys(co2PerPerson).map(key => {
                                return(<option label={key} value={co2PerPerson[key]}></option>)
                            })}
                        </select>
                    </p>
                <p><label>Average Budget kg/year</label>
                        <input
                            value={OutputData.co2PerPerson}></input>

                </p>
                <a href='https://www.laenderdaten.info/co2-nach-laendern.php' target='blank'>Data Source</a> 
            </div>
        )
    }
    return (
        <div className="co2-tracker">
            <div className="co2-inner-div">
                <h2>calculate your CO2 foodprint</h2>
                <div className="co2-head">
                    <div
                        className='header-button'
                        onClick={() => {
                            setActivePage('E-Device')
                        }}>E-Device</div>

                    <div
                        className='header-button'
                        onClick={() => {
                            setActivePage('E-Total')
                        }}>E-Total</div>

                    <div
                        className='header-button'
                        onClick={() => {
                            setActivePage('Car-KM')
                        }}>Car-KM</div>

                    <div
                        className='header-button'
                        onClick={() => {
                            setActivePage('Fuel-in-Liters')
                        }}>Fuel Liters</div>

                </div>

                
                {activePage === 'E-Device' && <div>
                    <div className="co2-input">
                        <p>
                            {BudgetInfo()}
                            <a href='https://app.electricitymaps.com/zone/AT' target="blank">Get CO2 live Data here</a>
                        </p>
                        <div><label>Leistung in Watt</label>
                            <input
                                type="number"
                                value={OutputData.power}
                                onChange={(e) => setData('power', e.target.value)}></input></div>
                        <br></br>
                        <div><label>co2 / kWh</label>
                            <input
                                type="number"
                                value={OutputData.co2}
                                onChange={(e) => setData('co2', e.target.value)}>
                            </input>                      
                        </div>
                        </div>
                    <div className="co2-output">
                        <br></br>
                        <label>Budget use</label><div className="co2"> {Math.round(100 / OutputData.budgetKg * calculateCo2(365, OutputData.co2, OutputData.power) * 10000) / 10000} %</div> <br></br>

                        <div>
                            <p>day</p>
                            <label>C02</label><div className="co2"> {calculateCo2(1, OutputData.co2, OutputData.power)} Kg/CO2</div> <br></br>
                            <label>Power</label><div className="co2"> {calculatePowerKwh(1, OutputData.co2, OutputData.power)} kWh</div> <br></br>
                        </div>

                        <p>week</p>
                        <label>CO2</label><div className="co2"> {calculateCo2(7, OutputData.co2, OutputData.power)} Kg/CO2 </div> <br></br>
                        <label>Power</label><div className="co2"> {calculatePowerKwh(7, OutputData.co2, OutputData.power)} kWh </div> <br></br>

                        <p>month</p>
                        <label>CO2</label><div className="co2"> {calculateCo2(30.5, OutputData.co2, OutputData.power)} Kg/CO2 </div> <br></br>
                        <label>Power</label><div className="co2"> {calculatePowerKwh(30.5, OutputData.co2, OutputData.power)} kWh </div> <br></br>

                        <p>year</p>
                        <label>CO2</label><div className="co2"> {calculateCo2(365, OutputData.co2, OutputData.power)} Kg/CO2 </div> <br></br>
                        <label>Power</label><div className="co2"> {calculatePowerKwh(365, OutputData.co2, OutputData.power)} kWh </div> <br></br>

                        <p>10 years</p>
                        <label>CO2</label><div className="co2"> {calculateCo2(3650, OutputData.co2, OutputData.power)} Kg/CO2 </div> <br></br>
                        <label>Power</label><div className="co2"> {calculatePowerKwh(3650, OutputData.co2, OutputData.power)} kWh </div> <br></br>
                    </div>

                </div>}

                {activePage === 'E-Total' && <div>
                    <div className="co2-input">
                        <p>
                            {BudgetInfo()}
                            <a href='https://app.electricitymaps.com/zone/AT' target="blank">Get CO2 live Data here</a>
                        </p>
                        <div><label>Leistung in kWh</label>
                            <input
                                type="number"
                                value={OutputData.kwh}
                                onChange={(e) => setData('kwh', e.target.value)}></input></div>
                        <br></br>
                        <div><label>gCO2 / kWh</label>
                            <input
                                type="number"
                                value={OutputData.co2}
                                onChange={(e) => setData('co2', e.target.value)}>
                            </input></div>
                        <br></br>

                    </div>
                    <div className="co2-output">
                        <br></br>

                        <div>
                            <p>Total</p>
                            <label>Budget use</label><div className="co2"> {Math.round(100 / OutputData.budgetKg * (OutputData.kwh * OutputData.co2 / 1000) * 10000) / 10000} %</div> <br></br>
                            <label>C02</label><div className="co2"> {OutputData.kwh * OutputData.co2 / 1000} Kg</div> <br></br>
                            {/* <label>Power</label><div className="co2"> {calculatePower(1, OutputData.co2, OutputData.power)} kWh</div> <br></br> */}
                        </div>

                    </div>

                </div>}
                {activePage === 'Car-KM' && 
                    <div className='co2-input'>
                        <p>
                            {BudgetInfo()}
                            <a href='https://www.europarl.europa.eu/news/de/headlines/society/20190313STO31218/co2-emissionen-von-pkw-zahlen-und-fakten-infografik' target='blank'>How bad are cars</a>
                        </p>
                        <p>
                            <label>Fuel Type</label>
                            <select
                                value={OutputData.fuelType}
                                onChange={(e) => setData('fuelType', e.target.value)}>
                                <option label='choose'>0</option>
                                <option label='Diesel'>2.8</option>
                                <option label='Benzin'>2.7</option>
                                <option label='LPG'>1.7</option>
                            </select>
                        </p>
                        <p>
                            <label>Consumption Liters/100km</label>
                            <input 
                                type='number'
                                value={OutputData.fuelConsumtion}
                                onChange={(e)=>setData('fuelConsumtion', e.target.value)}/>
                        </p>
                        <p>
                            <label>Distance in km</label>
                            <input
                                type='number'
                                value={OutputData.amountKilometers}
                                onChange={(e)=>setData('amountKilometers', e.target.value)}/>
                        </p>
                            <div className='co2-output'>
                                <h2>Results</h2>
                                <label>CO2:</label><div className='co2'>{Math.round(OutputData.amountKilometers / 100 * OutputData.fuelConsumtion * OutputData.fuelType*100 )/100} Kg</div><br></br>
                                <label>CO2 Budget ( year )</label><div className='co2'>{Math.round(100 / OutputData.budgetKg * OutputData.amountKilometers / 100 * OutputData.fuelConsumtion * OutputData.fuelType * 100) / 100} %</div><br></br>
                            </div>
                        </div>}
                {activePage === 'Fuel-in-Liters' && <div>
                    <div className="co2-input">
                        <p>
                             {BudgetInfo()}
                             <a href='https://www.europarl.europa.eu/news/de/headlines/society/20190313STO31218/co2-emissionen-von-pkw-zahlen-und-fakten-infografik' target='blank'>How bad are cars</a>
                       </p>
                        <p>
                            <label>Liters</label>
                            <input
                                type={'number'}
                                onChange={(e) => (setData('liters', e.target.value))}></input>
                        </p>
                        <p>
                            <label>Fuel Type</label>
                            <select
                                value={OutputData.fuelType}
                                onChange={(e) => setData('fuelType', e.target.value)}>
                                <option label='choose'>0</option>
                                <option label='Diesel'>2.8</option>
                                <option label='Benzin'>2.7</option>
                                <option label='LPG'>1.7</option>
                            </select>
                        </p>                 
                    </div>
                    <div className='co2-output'>
                        <h2>Results</h2>
                        <label>CO2:</label><div className='co2'>{Math.round(OutputData.liters * OutputData.fuelType *100 )/100} Kg</div><br></br>
                        <label>CO2 Budget ( year )</label><div className='co2'>{Math.round(100 / OutputData.budgetKg * OutputData.liters * OutputData.fuelType * 100) / 100} %</div><br></br>

                    </div>
                </div>}

            </div>
        </div>
    )
}