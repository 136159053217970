const co2PerPerson = {
    'Bahrain': 22730,
    'Afghanistan': 150,
    'Ägypten': 2280,
    'Albanien': 1720,
    'Algerien': 3880,
    'Äquatorialguinea': 3270,
    'Arabische Emirate': 20170,
    'Argentinien': 3670,
    'Äthiopien': 150,
    'Australien': 15050,
    'Bangladesch':540,
    'Belarus': 6170,
    'Belgien': 8020,
    'Bermuda': 9590,
    'Bolivien': 1850,
    'Bosnien und Herzegowina': 6440,
    'Brasilien': 2030,
    'Bulgarien': 5690,
    'Burundi':60,
    'Chile': 4690,
    'China': 7510,
    'Costa Rica': 1600,
    'Dänemark': 5070,
    'Deutschland': 7900,
    'Ecuador': 2210,
    'Eritrea':240,
    'Finnland': 7350,
    'Frankreich': 4440,
    'Griechenland': 5640,
    'Grönland': 9000,
    'Haiti':290,
    'Hongkong': 5890,
    'Indien': 1720,
    'Indonesien': 2260,
    'Iran': 7170,
    'Irland': 7100,
    'Island': 4400,
    'Israel': 6690,
    'Italien': 5370,
    'Japan': 8610,
    'Kambodscha':980,
    'Kamerun':350,
    'Kanada': 15170,
    'Kasachstan': 11160,
    'Kenia':420,
    'Kolumbien': 1570,
    'Kongo (Dem Rep)':30,
    'Kroatien': 4240,
    'Kuwait': 21800,
    'Liberia':230,
    'Luxemburg': 14830,
    'Macau': 3010,
    'Madagaskar':140,
    'Malawi':70,
    'Malediven': 4050,
    'Malta': 3200,
    'Marokko': 1930,
    'Mauritius': 3290,
    'Mexiko': 3550,
    'Moldawien': 3390,
    'Montenegro': 4200,
    'Myanmar':680,
    'Namibia': 1670,
    'Nepal':450,
    'Neukaledonien': 19660,
    'Neuseeland': 6640,
    'Niederlande': 8350,
    'Nigeria':540,
    'Nordkorea': 2160,
    'Norwegen': 6650,
    'Oman': 16820,
    'Österreich': 7230,
    'Osttimor':470,
    'Pakistan':820,
    'Panama': 3070,
    'Paraguay': 1220,
    'Peru': 1680,
    'Philippinen': 1280,
    'Polen': 7820,
    'Portugal': 4320,
    'Ruanda':100,
    'Rumänien': 3870,
    'Russland': 11880,
    'Saudi-Arabien': 14570,
    'Schweden': 3360,
    'Schweiz': 4290,
    'Serbien': 6720,
    'Singapur': 8690,
    'Spanien': 5060,
    'Sri Lanka': 1070,
    'Südafrika': 7400,
    'Sudan':450,
    'Südkorea': 11800,
    'Syrien': 1210,
    'Tansania':200,
    'Thailand': 3730,
    'Tschechien': 9170,
    'Türkei': 4680,
    'Turkmenistan': 11490,
    'Ukraine': 3990,
    'Ungarn': 4780,
    'Uruguay': 1890,
    'USA': 14520,
    'Venezuela': 3980,
    'Vereinigtes Königreich': 5180,
    'Vietnam': 345
}
export default co2PerPerson
